import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Haiku = () => {
  const data = useStaticQuery(graphql`
    query Haiku {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Für Hölderlin sind Worte wie Musik. Jedes Wort hat einen eigenen Klang und einen Rhythmus. 
        Beim Schreiben spricht Hölderlin die Worte oft laut vor sich hin, geht mit ihnen spazieren 
        oder klopft ihren Rhythmus auf den Tisch. Dabei achtet er genau auf die Zahl der Silben. 
        In einem Rundgang durch die Ausstellung schauen wir uns das genauer an: Wir lesen, 
        sprechen und klatschen Hölderlins Gedichte und gehen mit ihnen spazieren. Zuletzt bauen wir 
        aus Silben-Bausteinen und Gedicht-Schablonen selbst kurze Haiku-Gedichte zusammen.
      </Paragraph>
      <Paragraph>
        Für Kinder mit KreisBonusCard Junior ist der Kurs kostenfrei.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://www.fbs-tuebingen.de/kinder-und-jugendliche/kw/bereich/kursdetails/kurs/242-3208/kursname/Haiku-Werkstatt/kategorie-id/23/#inhalt">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Haiku