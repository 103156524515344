import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Schreibtherapie = () => {
  const data = useStaticQuery(graphql`
    query Schreibtherapie {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Kann Literatur Therapie sein? Wenn ja, wie und was vermag sie zu heilen? Kann Schreiben „kathartisch“ sein? 
        Wie unterscheidet sich literarisches Schreiben von einem therapeutischen Schreibansatz? 
        Ist Therapie eine Kunst wie die Literatur? Was verbirgt sich hinter der sog. Poesietherapie? 
        Im Rahmen des Seminars wollen wir uns anhand dieser Fragen der heilsamen Kraft des Schreibens 
        auf theoretische wie praktische Weise annähern. Mittels therapeutischer Schreibübungen, 
        die zugleich Elemente aus der Achtsamkeits- sowie gruppentherapeutischen Praxis enthalten, 
        wollen wir an uns selbst erproben, inwiefern das Schreiben die Möglichkeit eröffnet, 
        lebensgeschichtliche Erfahrungen und Konflikte literarisch zu transformieren. 
        In diesem Sinne ist das Seminar zugleich als hybride Form der Selbsterfahrung zwischen Literatur und Therapie zu verstehen, 
        die den nötigen Mut und eine gewisse Offenheit erfordert, mit sich selbst und anderen in einen vertrauensvollen Kontakt zu treten.
      </Paragraph>
      <Paragraph>
        Im Rahmen der Mental Health Days 2024 der Universität Tübingen - organisiert vom Studentischen Gesundheitsmanagement BeTaBalance
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://uni-tuebingen.de/einrichtungen/zentrale-einrichtungen/hochschulsport/sgm-betabalance/">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Schreibtherapie