import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Goldschmidt = () => {
  const data = useStaticQuery(graphql`
    query Goldschmidt {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
      </Paragraph>
    </Stack>
  )
}

export default Goldschmidt