import React, { useState, useEffect, useRef } from "react"
import { useThemeUI } from "theme-ui"
import { tint } from "@theme-ui/color"
import { Range, getTrackBackground } from "react-range"
import PropTypes from "prop-types"
import useSound from "use-sound"

import Box from "./box"
import Column from "./column"
import Columns from "./columns"
import Icon from "./icon"
import IconButton from "./icon-button"
import Inline from "./inline"
import PlayPauseButton from "./play-pause-button"
import Stack from "./stack"
import Text from "./text"

const TIME_PRECISION = 2

const useAnimationFrame = ({ callback, dependencies }) => {
  const requestRef = useRef()
  const previousTimeRef = useRef()

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

const round = (input, precision = 0) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(input * multiplier) / multiplier
}

const scale = (inputY, yRange, xRange) => {
  const [xMin, xMax] = xRange
  const [yMin, yMax] = yRange

  const percent = (inputY - yMin) / (yMax - yMin)
  const outputX = percent * (xMax - xMin) + xMin

  return outputX
}

function secondsToMinutes(seconds) {
  const min = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")
  const sec = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0")
  return `${min}:${sec}`
}

const PositionRange = ({ position, color, duration, isDisabled, onChange }) => {
  const context = useThemeUI()
  const { theme } = context
  const [isDragging, setIsDragging] = useState(false)
  const [internalPosition, setInternalPosition] = useState(0)
  // duration can still be 0 even if sound file is loaded
  const scaleFactor = 1000
  let scaledDuration = scaleFactor

  useEffect(() => {
    if (!isDragging && duration) {
      const scaledPosition = scale(position, [0, duration], [0, scaleFactor])
      setInternalPosition(scaledPosition)
    }
  }, [isDragging, position, duration])

  const onChangeRange = (values) => {
    if (isDisabled) {
      return
    }
    setIsDragging(true)
    setInternalPosition(values[0])
  }

  const onFinalChangeRange = (values) => {
    if (isDisabled) {
      return
    }
    const scaledBackPosition = scale(values[0], [0, scaleFactor], [0, duration])
    onChange({ position: scaledBackPosition })
    setIsDragging(false)
  }

  return (
    <Range
      step={1}
      min={0}
      max={scaledDuration}
      disabled={!duration}
      values={[internalPosition]}
      onChange={onChangeRange}
      onFinalChange={onFinalChangeRange}
      renderTrack={({ props, children }) => (
        <Box
          {...props}
          sx={{
            height: 1,
            width: "100%",
            background: getTrackBackground({
              values: [internalPosition],
              colors: [theme.colors[color], theme.colors.border],
              min: 0,
              max: scaledDuration,
            }),
            borderRadius: "9999em",
          }}
        >
          {children}
        </Box>
      )}
      renderThumb={({ props }) => (
        <Box
          {...props}
          sx={{
            height: 4,
            width: 4,
            bg: "white",
            borderRadius: "100%",
            boxShadow:
              "0 0 3px 0 rgba(0,0,0,0.1), 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)",
            ":hover, :focus": {
              bg: "white",
            },
            ":focus": {
              outline: 0,
              boxShadow: (theme) => {
                tint(color, 0.1)(theme)
              },
            },
          }}
        />
      )}
    />
  )
}

const AudioPlayer = ({ title, color, src }) => {
  const [muted, setMuted] = useState(false)
  const [position, setPosition] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [failed, setFailed] = useState(false)
  const [play, { pause, stop, isPlaying, duration, sound }] = useSound(
    `${process.env.GATSBY_MEDIA_URL}/audio/${src}.mp3`,
    /* `../audio/${src}`, */
    {
      onload: () => {
        setLoaded(true)
      },
      onloaderror: () => {
        setFailed(true)
      },
      html5: true,
    }
  )

  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])

  useAnimationFrame({
    callback: (deltaTime) => {
      if (sound && loaded) {
        const seekPosition = sound.seek()
        const roundedSeekPosition = round(seekPosition, TIME_PRECISION)
        if (!isNaN(roundedSeekPosition)) {
          setPosition(roundedSeekPosition)
        }
      }
    },
    dependencies: [sound, setPosition, loaded],
  })

  const onTogglePlay = (event) => {
    event.preventDefault()
    if (!loaded || failed) {
      return
    }
    if (isPlaying) {
      pause()
    } else {
      play()
    }
  }

  const onToggleMute = (event) => {
    event.preventDefault()
    setMuted(!muted)
    if (loaded) {
      sound.mute(!muted)
    }
  }

  const onChangePosition = ({ position }) => {
    if (!loaded || failed) {
      return
    }
    sound.seek(position)
    setPosition(round(position, TIME_PRECISION))
  }

  return (
    src && (
      <Box bg="muted" borderRadius="lg" p={4}>
        <Columns alignY="center" space={4}>
          <Column width="content">
            <PlayPauseButton
              color={color}
              onClick={onTogglePlay}
              isLoading={!loaded}
              isDisabled={failed}
              isPlaying={isPlaying}
            />
          </Column>
          <Column>
            {failed ? (
              <Inline space={2}>
                <Icon icon="form-caution" color="caution" />
                <Text sans>
                  Die Audio-Datei konnte leider nicht geladen werden.
                </Text>
              </Inline>
            ) : (
              <Stack space={4}>
                {title && <Text sans>{title}</Text>}
                <Stack space={2}>
                  <PositionRange
                    onChange={onChangePosition}
                    color={color}
                    position={position}
                    duration={duration / 1000}
                    isDisabled={!loaded || failed}
                  />
                  <Columns alignY="center">
                    <Column>
                      <Text
                        color="whisper"
                        bold
                        size="10px"
                        sx={{ fontFamily: "mono" }}
                      >
                        {secondsToMinutes(position)}{" "}
                        <Text as="span" color="whisper" sans>
                          |
                        </Text>{" "}
                        {secondsToMinutes(duration / 1000)}
                      </Text>
                    </Column>
                    <Column width="content">
                      <IconButton
                        size={4}
                        color="whisper"
                        aria-label="Lautstärke anpassen"
                        mr={-1}
                        icon={muted ? "volume-mute" : "volume-up"}
                        onClick={onToggleMute}
                      />
                    </Column>
                  </Columns>
                </Stack>
              </Stack>
            )}
          </Column>
        </Columns>
      </Box>
    )
  )
}

AudioPlayer.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
}

AudioPlayer.defaultProps = {
  color: "primary",
}

export default AudioPlayer
